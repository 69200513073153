
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&family=Josefin+Sans:wght@500&display=swap');


body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.App {
  text-align: center;
}

section.ant-layout {
  min-height: 100%;
}

.ant-layout-content > div {
  min-height: 100vh;
}

section.login-page-container {
  height: 100vh;
  background-image: url(./assets/login-bg.svg);
}

.login-page-container .login-container {
  background-color: white;
  padding: 25px;
  border-radius: 5px;
}

div#prism-logo {
  font-size: 2em;
  color: #2062a3;
}

.login-page-container .login-form-header {
  text-align: center;
  margin-bottom: 30px;
}


.login-page-container .login-form .login-form-button{
  width: 100%;
}


#root {
  height:100%;
  font-family: sans-serif;
}

div.Center {
  margin: auto;
  width: 40%;
}

.login-form {
  margin: auto;
}

div.layout-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

div.layout-header > div:last-child{
  padding-right: 4em;
}

#user-badge {
  margin-top: 10em;
  margin-left: 0.5em;
}

.ant-layout-header > .ant-dropdown-link {
  float: right;
  margin-right: 20px;
  color: rgb(235, 235, 235);
}

.site-layout-sub-header-background {
  background: rgb(230, 230, 230);
}

.site-layout-background {
  background: #fff;
}

#logo {
  font-family: 'Josefin Sans';
  font-size:large;
  /* color: white; */
  height: 32px;
  margin: 16px;
  margin-left: 15px;
}

#navbar {
  font-size: medium;
}

ul.ant-menu-sub {
  background-color: #001529 !important;
}

.gbutton-container {
  width: 100%;
  text-align: center;
}

.gbutton {
  background-color: #4284f3 !important;
  color: white !important;
  font-size: 16px !important;
}

.gbutton span{
  padding: 10px 20px 10px 10px !important;
}

.gbutton div{
  padding: 10px 15px !important;
}

.ant-menu-item {
  color: red
}
